import { HACK_sessionProvider } from "../../contexts/SessionProviderContext/SessionProviderContext";
const nodeName = "authenticate.login";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Log In",
    category: "User",
    color: "green",
    inputs: {
        provider: {
            displayName: "Provider",
            type: "string",
            group: "General",
        },
    },
    signals: {
        async Do() {
            if (this.logInScheduled === true)
                return;
            this.logInScheduled = true;
            this.scheduleAfterInputsHaveUpdated(() => {
                this.logInScheduled = false;
                const { signInWithProvider } = HACK_sessionProvider.getState();
                signInWithProvider(this.inputs.provider);
            });
        },
    },
});
