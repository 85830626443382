import { createQueryModelNode } from "../../crudUtilities";
const nodeName = "vhc.measurement.query";
export const node = createQueryModelNode({
    nodeName,
    modelName: "Measurement",
    endpoint: "/measurement",
    inputs: {
        workspaceId: {
            type: "string",
            displayName: "Workspace ID",
            group: "Data",
        },
    },
});
