import { HACK_sessionProvider, } from "../../contexts/SessionProviderContext/SessionProviderContext";
const nodeName = "authenticate.user";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "User",
    category: "User",
    color: "green",
    getInspectInfo() {
        return [
            {
                type: "value",
                value: this.outputs,
            },
        ];
    },
    outputs: {
        loggedin: {
            type: "signal",
            displayName: "Logged In",
            group: "Events",
        },
        loggedout: {
            type: "signal",
            displayName: "Logged Out",
            group: "Events",
        },
        isLoggedin: {
            displayName: "Is Logged In",
            type: "boolean",
            group: "General",
        },
        name: {
            displayName: "Name",
            type: "string",
            group: "General",
        },
        email: {
            displayName: "Email",
            type: "string",
            group: "General",
        },
        image: {
            displayName: "Image",
            type: "string",
            group: "General",
        },
    },
    initialize() {
        const self = this;
        function updateState(state, prevState) {
            if (state && prevState) {
                if (state.status === "authenticated" &&
                    prevState.status !== "authenticated") {
                    self.sendSignalOnOutput("loggedin");
                }
                else if (state.status !== "authenticated" &&
                    prevState.status === "authenticated") {
                    self.sendSignalOnOutput("loggedout");
                }
            }
            self.setOutputs({
                isLoggedin: state.status === "authenticated",
                name: state.session?.user?.name,
                email: state.session?.user?.email,
                image: state.session?.user?.image,
            });
        }
        updateState(HACK_sessionProvider.getState(), null);
        // TODO: Call this.sub somewhere to clean up
        this.sub = HACK_sessionProvider.subscribe(updateState);
    },
});
