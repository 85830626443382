import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { createStore } from "zustand/vanilla";
const SessionContext = createContext({
    status: "init",
    session: null,
    signInWithProvider: function (provider) {
        throw new Error("Function not implemented.");
    },
    signOut: function () {
        throw new Error("Function not implemented.");
    },
    fetchSession: function () {
        throw new Error("Function not implemented.");
    },
});
export const HACK_sessionProvider = createStore()(() => ({
    status: "init",
    session: null,
    signInWithProvider: function (provider) {
        throw new Error("Function not implemented.");
    },
    signOut: function () {
        throw new Error("Function not implemented.");
    },
    fetchSession: function () {
        throw new Error("Function not implemented.");
    },
}));
function SessionProviderContext({ children, }) {
    const settings = Noodl.getProjectSettings();
    const endpoint = settings.virtualHapticCloudEndpoint;
    const [status, setStatus] = useState("init");
    const [session, setSession] = useState(null);
    const fetchSession = async () => {
        const response = await fetch(endpoint + "/auth/session", {
            credentials: "include",
        });
        const json = await response.json();
        console.log(json);
        setSession(json);
        setStatus(response.status === 200 ? "authenticated" : "unauthenticated");
    };
    const signInWithProvider = async (provider) => {
        location.href = endpoint + "/auth/auth/" + provider;
    };
    const signOut = async () => {
        await fetch(endpoint + "/auth/signOut", {
            credentials: "include",
        });
    };
    if (status === "init") {
        HACK_sessionProvider.setState({
            status,
            session,
            signInWithProvider,
            signOut,
        });
        // TODO: Use React Query
        setStatus("loading");
        fetchSession();
        return null;
    }
    HACK_sessionProvider.setState({
        status,
        session,
    });
    if (session?.user) {
        // We know the type... Auth.js have bad types
        const userSession = session;
        Noodl.Object.create({
            id: userSession.userId,
            name: userSession.user.name,
            email: userSession.user.email,
            image: userSession.user.image,
        });
    }
    // While loading the authenticate state, don't process the children,
    // this solves the issue of navigation wether the user is logged in or not.
    if (["init", "loading"].includes(status)) {
        return null;
    }
    return (_jsx(SessionContext.Provider
    // @ts-expect-error
    , { 
        // @ts-expect-error
        status: status, session: session, signInWithProvider: signInWithProvider, signOut: signOut, children: children }));
}
export function useSessionContext() {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error("useSessionContext must be a child of SessionProviderContext");
    }
    return context;
}
const nodeName = "authenticate.sessionProvider";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Session Provider",
    category: "Visual",
    getReactComponent() {
        return SessionProviderContext;
    },
});
