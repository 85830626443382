import * as Noodl from "@noodl/noodl-sdk";
import * as SessionProviderContext from "./contexts/SessionProviderContext/SessionProviderContext";
import * as Auth from "./nodes/auth";
import * as ApiKey from "./nodes/routes/apiKey";
import * as HandGroup from "./nodes/routes/handGroup";
import * as Invites from "./nodes/routes/invite";
import * as Measurement from "./nodes/routes/measurement";
import * as Organizations from "./nodes/routes/organization";
import * as Workspaces from "./nodes/routes/workspace";
import * as Users from "./nodes/routes/user";
Noodl.defineModule({
    reactNodes: [SessionProviderContext.node],
    nodes: [
        ...Auth.nodes,
        ...ApiKey.nodes,
        ...HandGroup.nodes,
        ...Invites.nodes,
        ...Measurement.nodes,
        ...Organizations.nodes,
        ...Workspaces.nodes,
        ...Users.nodes,
    ],
    settings: [
        {
            type: "string",
            name: "virtualHapticCloudEndpoint",
            displayName: "Endpoint",
            group: "Virtual Haptic Cloud",
        },
    ],
});
