import { getEndpoint, makeRequest } from "../api";
import { mapObjectToNoodl, objectToNoodl } from "../utils";
/**
 * Create a new Noodl node, designed only for querying models.
 *
 * @param param0
 * @returns
 */
export function createQueryModelNode({ nodeName, modelName, endpoint, inputs, }) {
    return Noodl.defineNode({
        name: nodeName,
        displayName: `Query ${modelName}`,
        category: "Tailr",
        color: "green",
        inputs,
        outputs: {
            success: {
                type: "signal",
                displayName: "Success",
                group: "Events",
            },
            failure: {
                type: "signal",
                displayName: "Failure",
                group: "Events",
            },
            error: {
                type: "string",
                displayName: "Error",
                group: "Error",
            },
            items: {
                type: "array",
                displayName: "Items",
                group: "Data",
            },
        },
        signals: {
            async Do() {
                if (this.scheduled === true)
                    return;
                this.scheduled = true;
                this.scheduleAfterInputsHaveUpdated(() => {
                    this.scheduled = false;
                    makeRequest(endpoint, {
                        method: "GET",
                        body: this.inputs,
                    })
                        .then((response) => {
                        this.setOutputs({
                            items: response.data.items.map(mapObjectToNoodl(modelName)),
                        });
                        this.sendSignalOnOutput("success");
                    })
                        .catch((error) => {
                        this.setError(error);
                    });
                });
            },
        },
        methods: {
            setError(error) {
                this.setOutputs({
                    error,
                });
                this.sendSignalOnOutput("failure");
                if (this.context.editorConnection) {
                    this.context.editorConnection.sendWarning(this.nodeScope.componentOwner.name, this.id, "api-warning", {
                        message: error,
                        showGlobally: true,
                    });
                }
            },
            clearWarnings() {
                if (this.context.editorConnection) {
                    this.context.editorConnection.clearWarning(this.nodeScope.componentOwner.name, this.id, "api-warning");
                }
            },
        },
    });
}
export function createGetModelNode({ nodeName, modelName, endpoint, method = "GET", outputs, onSuccess, }) {
    return Noodl.defineNode({
        name: nodeName,
        displayName: `Get ${modelName}`,
        category: "Tailr",
        color: "green",
        inputs: {
            id: {
                type: "string",
                displayName: "ID",
                group: "Data",
            },
        },
        outputs: {
            success: {
                type: "signal",
                displayName: "Success",
                group: "Events",
            },
            failure: {
                type: "signal",
                displayName: "Failure",
                group: "Events",
            },
            error: {
                type: "string",
                displayName: "Error",
                group: "Error",
            },
            requestUrl: {
                type: "string",
                displayName: "Request URL",
                group: "Request",
            },
            ...(outputs || {
                item: {
                    type: "*",
                    displayName: "Item",
                    group: "Data",
                },
            }),
        },
        changed: {
            id(value) {
                this.setOutputs({
                    // Output the full URL, useful for the File URL node, where a GET
                    // request will redirect.
                    requestUrl: getEndpoint() + endpoint.replace("{id}", value),
                });
            },
        },
        signals: {
            async Do() {
                if (this.scheduled === true)
                    return;
                this.scheduled = true;
                this.scheduleAfterInputsHaveUpdated(() => {
                    this.scheduled = false;
                    const url = endpoint.replace("{id}", this.inputs.id);
                    makeRequest(url, {
                        method,
                    })
                        .then((response) => {
                        this.setOutputs(onSuccess
                            ? onSuccess(response)
                            : {
                                item: objectToNoodl(response.data, modelName),
                            });
                        this.sendSignalOnOutput("success");
                    })
                        .catch((error) => {
                        this.setError(error);
                    });
                });
            },
        },
        methods: {
            setError(error) {
                this.setOutputs({
                    error,
                });
                this.sendSignalOnOutput("failure");
                if (this.context.editorConnection) {
                    this.context.editorConnection.sendWarning(this.nodeScope.componentOwner.name, this.id, "api-warning", {
                        message: error,
                        showGlobally: true,
                    });
                }
            },
            clearWarnings() {
                if (this.context.editorConnection) {
                    this.context.editorConnection.clearWarning(this.nodeScope.componentOwner.name, this.id, "api-warning");
                }
            },
        },
    });
}
export function createCreateModelNode({ nodeName, modelName, endpoint, inputs, outputs, onSuccess, }) {
    return Noodl.defineNode({
        name: nodeName,
        displayName: `Create ${modelName}`,
        category: "Tailr",
        color: "green",
        inputs,
        outputs: {
            success: {
                type: "signal",
                displayName: "Success",
                group: "Events",
            },
            failure: {
                type: "signal",
                displayName: "Failure",
                group: "Events",
            },
            finally: {
                type: "signal",
                displayName: "Finally",
                group: "Events",
            },
            error: {
                type: "string",
                displayName: "Error",
                group: "Error",
            },
            ...outputs,
        },
        signals: {
            async Do() {
                if (this.scheduled === true)
                    return;
                this.scheduled = true;
                this.scheduleAfterInputsHaveUpdated(() => {
                    this.scheduled = false;
                    const body = Object.fromEntries(Object.keys(inputs).map((key) => [key, this.inputs[key]]));
                    makeRequest(endpoint, {
                        method: "POST",
                        body,
                    })
                        .then((response) => {
                        this.setOutputs(onSuccess(response));
                        this.sendSignalOnOutput("success");
                    })
                        .catch((error) => {
                        this.setError(error);
                    })
                        .finally(() => {
                        this.sendSignalOnOutput("finally");
                    });
                });
            },
        },
        methods: {
            setError(error) {
                this.setOutputs({
                    error,
                });
                this.sendSignalOnOutput("failure");
                if (this.context.editorConnection) {
                    this.context.editorConnection.sendWarning(this.nodeScope.componentOwner.name, this.id, "api-warning", {
                        message: error,
                        showGlobally: true,
                    });
                }
            },
            clearWarnings() {
                if (this.context.editorConnection) {
                    this.context.editorConnection.clearWarning(this.nodeScope.componentOwner.name, this.id, "api-warning");
                }
            },
        },
    });
}
