export function getEndpoint() {
    // @ts-expect-error
    const settings = Noodl.getProjectSettings();
    return settings.virtualHapticCloudEndpoint;
}
export async function makeRequest(path, { method = "POST", body, contentType = "application/json" }) {
    const headers = {};
    if (contentType !== null) {
        headers["Content-Type"] = contentType;
    }
    if (method === "GET" && body && !(body instanceof FormData)) {
        // @ts-expect-error Lets just expect Record<string, string>
        const queryString = new URLSearchParams(body).toString();
        if (queryString.length) {
            path += "?" + queryString;
        }
    }
    const response = await fetch(getEndpoint() + path, {
        method: method || "POST",
        headers,
        body: ["GET"].includes(method)
            ? undefined
            : body instanceof FormData
                ? body
                : JSON.stringify(body),
        credentials: "include",
    });
    const json = await response.json();
    if (response.status < 200 && response.status >= 300) {
        throw new Error("invalid status code");
    }
    return json;
}
