import { HACK_sessionProvider } from "../../contexts/SessionProviderContext/SessionProviderContext";
const nodeName = "authenticate.logout";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Log Out",
    category: "User",
    color: "green",
    outputs: {
        failure: {
            type: "signal",
            displayName: "Failure",
            group: "Events",
        },
        error: {
            type: "string",
            displayName: "Error",
            group: "Error",
        },
    },
    signals: {
        async Do() {
            if (this.logOutScheduled === true)
                return;
            this.logOutScheduled = true;
            this.scheduleAfterInputsHaveUpdated(() => {
                this.logOutScheduled = false;
                const { signOut } = HACK_sessionProvider.getState();
                signOut()
                    .then(() => {
                    // We wont do anything else, and this is a clean way to ensure no
                    // other strange things happen.
                    window.location.reload();
                })
                    .catch((e) => {
                    this.setError(e);
                });
            });
        },
    },
    methods: {
        setError(error) {
            this.setOutputs({
                error,
            });
            this.sendSignalOnOutput("failure");
            if (this.context.editorConnection) {
                this.context.editorConnection.sendWarning(this.nodeScope.componentOwner.name, this.id, "user-login-warning", {
                    message: error,
                    showGlobally: true,
                });
            }
        },
        clearWarnings() {
            if (this.context.editorConnection) {
                this.context.editorConnection.clearWarning(this.nodeScope.componentOwner.name, this.id, "user-login-warning");
            }
        },
    },
});
