import { createQueryModelNode } from "../../crudUtilities";
const nodeName = "vhc.handGroup.query";
export const node = createQueryModelNode({
    nodeName,
    modelName: "Hand Group",
    endpoint: "/handGroup",
    inputs: {
        organizationId: {
            type: "string",
            displayName: "Organization ID",
            group: "Data",
        },
    },
});
