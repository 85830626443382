import { createCreateModelNode } from "../../crudUtilities";
const nodeName = "vhc.invite.create";
export const node = createCreateModelNode({
    nodeName,
    modelName: "Invite",
    endpoint: "/invite",
    inputs: {
        email: {
            type: "string",
            displayName: "Email",
            group: "Data",
        },
        organizationId: {
            type: "string",
            displayName: "Organization ID",
            group: "Data",
        },
    },
    outputs: {
        item: {
            type: "object",
            displayName: "Item",
            group: "Data",
        },
    },
    onSuccess: (response) => ({
        item: response.item,
    }),
});
